<template>
  <v-card class="rd elevation-0" >
    <img class="img" :src="src" v-if="!isMobile"  @click="imgClick" style="cursor: pointer" />
     <img class="imgMobile" :src="src" v-if="isMobile"  @click="imgClick" style="cursor: pointer" />
    <div class="name">{{ pageValue.name || "No Name" }}</div>
    <div class="num">{{ category }} </div>
    <slot></slot>
  </v-card>
</template>

<script>
import { getFileSrc } from "@/utils/file";
const WORK_KEY = "WORK";

const AVATAR_KEY = "AVATAR";
import defaultImg from "@/assets/default.png";
import Avatar from "@/components/avatar/index";

export default {
  components: {	Avatar	},
  props: {
    hash: {
      type: String,
    },
    pageValue: {
      type: Object,
    },
    showAvatar: {
      type: Boolean,
      default: false
    },
    otherpage: {
      type: Boolean,
      default: false
    },
    type:{
       type: String,
    },
     collectfrom: {
        type: String,
      
      },
      collectsource:{
         type: String,
      },
      did:{
         type: String,
      },
      category:{
        type: String,
      }
     
  },
  data: () => ({
    src: undefined,
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    hash: {
      handler: async function (hash) {
        console.log("wxl --- hash",hash)
        if (!hash) {
          hash = "QmPcXBirGiUSyjb8i2FQUpNDTtPHBsgo7tviwwm4YQeJ6p"
        }
        this.src = await getFileSrc(WORK_KEY, hash);
      },
      immediate: true,
    },
     category: {
 
      handler: async function (category) {
            console.log("wxl -- dddd",category)
            if(category == 1){
            this.category = 'PFP'
          }else if(category == 2){
            this.category = 'Artwork'
          }else if(category == 3){
            this.category = 'Collectibles'
          }else if(category == 4)
          {
            this.category = 'Photography'
          }
      },
      immediate: true,
    },
  },
 
  async mounted() {
  },
  methods: {
   
    imgClick() {
        let imgObj={}
        imgObj.imgUrl = this.pageValue.imgUrl
        imgObj.src = this.src
        imgObj.name = this.pageValue.name
        imgObj.amount = this.pageValue.amount
        imgObj.contractAddress = this.pageValue.contractAddress
        imgObj.contractId = this.pageValue.contractId
        imgObj.contractCollectionId = this.pageValue.contractCollectionId    
        imgObj.linkUrl = this.pageValue.linkUrl
        imgObj.category = this.category
        this.$emit('collectClick',imgObj)

    },
  },
};
</script>


<style lang="scss" scoped>
.v-card {
    text-align: center;

  .img {
     margin: 15px 20px;
     width: 83px;
	height: 75px;
	background-color: #f8f6fd;
	border-radius: 5px;
    object-fit: cover;
  }
  .imgMobile{
      margin: 15px 10px;
     width: 83px;
	height: 75px;
	background-color: #f8f6fd;
	border-radius: 5px;
    object-fit: cover;

  }

  .name {
    margin-top:-10px;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 13px;
 
	  color: #270645;;
   text-align: center;
    font-weight: bold !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .num {

    font-family: Helvetica;
    font-weight: bold;
    font-size: 12px;
   
    color: #766983;

  }

}
</style>