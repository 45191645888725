<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="main d-flex flex-lg-row flex-column">
      <div class="card_img d-flex flex-column mr-lg-16">
        <div style="font-size: 23px;color: #270645;font-weight: bold;margin-bottom:30px">{{ $t("create_nft") }}</div>
        <img
          v-if="uploadFileUrl"
          class="clo"
          width="31px"
          height="31px"
          @click.stop="closeDialog"
          src="@/assets/btn_close.png"
          alt=""
        />

        <div
          class="d-flex flex-column justify-center align-center creatimg"
          style="
            width: 451px;
            height: 450px;
            border: dashed 2px #e3e3e3;
            position: relative;
          "
        >
          <FIleInput accept=".jpg,.png,.gif,.mp4,.jpeg" @change="change" v-if="!uploadFileUrl" :key="fileInputKey">
            <div class="uploadbox drop_area " :class="{'dropwait':isDrop}" @drop="enentDrop" @dragleave="dragleave" @dragenter="dragleave" @dragover="dragleave">
              <div class="drop_txt"></div>
              <template>
                <v-btn dark width="161px" height="40px" rounded
                  >{{ $t("createChooseFile") }}
                </v-btn>
                <div class="mt-5">{{ $t("createLimit") }}</div>
              </template>
              <div class="progress_area" v-if="isDrop">
                  <span :style="{'width':progressValue+'%'}"></span>
              </div>
            </div>
          </FIleInput>
          <video
            v-else-if="fileType == 'video/mp4'"
            :src="uploadFileUrl"
            width="100%"
            height="100%"
            contain
            loop
            controls
            controlslist="nodownload"
          ></video>
          <v-img
            v-else
            :src="uploadFileUrl"
            width="100%"
            height="100%"
            contain
          ></v-img>
        </div>
        <div>
          <div class="d-flex flex-row">
             <div class="title mt-3 mb-2">{{ $t("createName") }}</div>
            <span class="star">*</span>
          </div>
         
          <div class="box">
            <v-text-field
              ref="nameValue"
              v-model="nameValue"
              :rules="nameRules"
              label=""
              outlined
              :key="refresh_key"
            ></v-text-field>
          </div>
        </div>
        <div>
          <div class="d-flex flex-row">
             <div class="title mt-3 mb-2">
            {{ $t("popupAccountAppealDescription") }}
          </div>
          <span class="star">*</span>
          </div>
         
          <div class="box">
            <v-textarea
              ref="descriptionValue"
              :rules="descRules"
              outlined
              name="input-7-4"
              label=""
              value=""
              v-model="descriptionValue"
              :key="refresh_key"
            ></v-textarea>
          </div>
        </div>

     

        <div>
          <div class="title mt-3 mb-2">{{ $t("createCategories") }}</div>
        <div class=" d-flex flex-row">
          <div class="any d-flex flex-row" @click="iconBtn1">
           <img
                style="background-color: white;width: 20px;height: 20px;"
                src="../../assets/icons/icon_dx_n.png"
                alt="1"
                v-if="isNft"
              />
              <img
                style="background-color: white;width: 20px;height: 20px;"
                src="../../assets/icons/icon_dx_y.png"
                alt="2"
                v-else
              />
          <span class="ml-2">{{ $t("create_nft_type0") }}</span>
          </div>
           <div class="any d-flex flex-row ml-14" @click="iconBtn2">
           <img
                style="background-color: white;width: 20px;height: 20px;"
                src="../../assets/icons/icon_dx_n.png"
                alt="1"
                v-if="isCollect"
              />
              <img
                style="background-color: white;width: 20px;height: 20px;"
                src="../../assets/icons/icon_dx_y.png"
                alt="2"
                v-else
              />
           <span class="ml-2">{{ $t("create_nft_type1") }}</span>
          </div>
            <div class="any d-flex flex-row ml-14" @click="iconBtn3" >
           <img
                style="background-color: white;width: 20px;height: 20px;"
                src="../../assets/icons/icon_dx_n.png"
                alt="1"
                v-if="isFile"
              />
              <img
                style="background-color: white;width: 20px;height: 20px;"
                src="../../assets/icons/icon_dx_y.png"
                alt="2"
                v-else
              />
           <span class="ml-2" >{{ $t("create_nft_type2") }}</span>
          </div>
        </div>
        <div class="mb-4 mt-3" v-if="!isNft" style="font-size: 13px;color: #766983;" v-html="this.$t('create_nft_des0')"></div>
        <div class="mb-4 mt-3" v-if="!isCollect" style="font-size: 13px;color: #766983;" v-html="this.$t('create_nft_des1')"></div>
        <div class="mb-4 mt-3" v-if="!isFile" style="font-size: 13px;color: #766983;" v-html="this.$t('create_nft_des2')"></div>
        <div class="chooseCollection d-flex justify-space-between mb-5" v-if='isNft'>
            <div class="d-flex flex-lg-row">
               <div class="createCollect"  @click="createCollect" :class="{ changerBorde: ischange }">
              <div class="add">
                <img class="addCheck" src="@/assets/icons/icon_build.png"/>
              </div>
              <div class="name"> {{$t('headerBtnCreate')}}</div>
              <div class="type" v-if="!isCollect">ERC-721</div>
               <div class="type" v-else>{{$t('Folders')}}</div>
            </div>
            <div class="uptickCollect  ml-lg-2" v-for="(item,index) in recentlyCollectList" :key='index' @click="itemClicked(item,index)" :class="{ changerBorde: collectId == index }"  >
              <img class="add" :src="getImageIpfs(item.imgUrl)" />
              <div class="name">{{item.name}} </div>
              <div class="type">{{item.category}}</div>
            </div>
            </div>
           
            <div class="mycollect ml-lg-2" @click="cardlist">
              <v-icon class="mt-15" color="primary">mdi-menu-down</v-icon>
            </div>
          </div>
          <div class="box" v-if="this.isCollect">
            <v-select
              :items="CategoryList"
              :label="this.$t('otherpageTabsPageArtwork')"
              item-text="texts"
              item-value="value"
              v-model="categoryValue"
              solo
              :disabled="categoryValue==4 && this.fileType=='video/mp4'"
            ></v-select>
          </div>

          
        
        </div>
       
        <div class="mb-4 box"   v-if="this.isCollect">
          <div   class="title mt-3 mb-2">{{ $t("createAmount") }}</div>
          <div>
            <!-- <v-select
               v-if="this.isCollect"
              :items="AmountList"
              item-text="text"
              item-value="value"
              :label="this.$t('createProducts')"
              v-model="amountListValue"
              solo
            ></v-select> -->
            <v-text-field
             ref="amountValue"
              :rules="[rules.EditionValue]"
        
              v-model="amountValue"
              :suffix="this.$t('createAmountEdition')"
              label=""
              outlined
              solo
              :disabled="this.amountListValue === 2 || this.isCollect == false"
            ></v-text-field>
          </div>
        </div>
               <div>
          <div class="d-flex flex-row Properties" style="position: relative;">
              <div class="title  ">{{ $t("Properties") }} </div>
             <img class="icon" style="width:20px;height:20px;margin-top:7px;margin-left:10px;" @mouseenter="moused()"
                @mouseleave="leave()" src="@/assets/icons/icon_wen.png"  />
             <div class="explain d-flex flex-row align-center" v-if="isshow" >
                <span>{{ $t("Properties_des") }}</span>
              </div>
          </div>
         
            <div class="list">
              <div class="item d-flex flex-row" v-for="(item,index) in prodList" :key="index">
                <div class="itemkey  d-flex flex-column">
                  <div>Key</div>
                 <!-- <v-text-field ref="nameValue" v-model="item.key"  label="" outlined :key="refresh_key"></v-text-field> -->
                  <input  v-model="item.trait_type" type="text" style="text-align:left" :maxlength="10">
                </div>
                 <div class="valuekey  d-flex flex-column ml-3">
                  <div>Value</div>
                 <!-- <v-text-field ref="nameValue" v-model="item.value"  label="" outlined :key="refresh_key"></v-text-field> -->
                 <input  v-model="item.value" type="text" style="text-align:left" :maxlength="20">
                </div>
                <div class="img">
                  <img class="icon" src="@/assets/icons/icon_d.png" @click="imgRemove(index)" />
                </div>

              </div>

            </div>
        </div>
         <div>
          <button  class="add_btn mt-5" @click="addpropertie" v-if="prodList.length< 64"> + {{ $t("Add_propert")}}</button>
        </div>
          <div>
          <div class="d-flex flex-row mt-9 mb-3" style="position: relative;">
            <v-checkbox v-model="lincensebox"></v-checkbox>
              <div class="title  ">{{ $t("License_CC0")}}</div>
             <img class="icon" style="width:20px;height:20px;margin-top:22px;margin-left:10px;" @mouseenter="mousedlincense()"
                @mouseleave="leavelincense()" src="@/assets/icons/icon_wen.png"  />
             <div class="explainlincense d-flex flex-row align-center" v-if="isshowLincense" >
                <span>{{ $t("License_CC0_des")}} </span>
              </div>
          </div>
        </div>

        <div class="switch box d-flex flex-row mb-13">
          <div class="titleInfo">{{ $t("createInformation") }}</div>
          <div class="s_witch mt-n4">
            <v-switch v-model="switch1" inset label=""></v-switch>
          </div>
        </div>
              <div class="mb-4 box d-flex justify-space-between">
          <div class="descb d-flex flex-column">
            <span class="Viewable">{{ $t("Viewable") }}</span>
            <span class="publicly">{{ $t("Viewable_des") }}</span>
          </div>
          <div class="eye" @click="clickEyes">
            <img
              class="eyeimg"
              :src="
                require('@/assets/icons/' +
                  (isVisible ? 'eye' : 'eye1') +
                  '.png')
              "
            />
          </div>
        </div>
                <div class="title box mb-4">
          {{ $t("createLssuer") }}：{{ issuerName ? issuerName :userdid }}
        </div>
        <div class="copyright box d-flex flex-row mb-8">
          <div class="checkbox mt-n5">
            <v-checkbox v-model="checkbox" label=""></v-checkbox>
          </div>
          <div class="content">
            {{ $t("createPleaseEnsure") }}
            <p>
              {{ $t("createAgreed")
              }}<span style="cursor: pointer;" @click="toTerms"
                >《{{ $t("createCopyright") }}》</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="look ml-lg-3" style="position: relative;margin-top: 60px;z-index:100;">
		  <img
		    v-if="uploadImageUrl"
		    class="clo"
		    width="31px"
		    height="31px"
		    @click.stop="closeImgDialog"
		    src="@/assets/btn_close.png"
		    alt=""
		  />
		  <FIleInput accept=".jpg,.png,.gif,.jpeg" class="imgfile" @change="imgChange" v-if="showUploadImage" :key="fileInputKey">
		    <div class="uploadbox drop_area " style="width: 220px; height: 230px; margin-left: -56px; margin-top: -96px;"
                :class="{'dropwait':isDropImg}" @drop="enentDropImg" @dragleave="dragleave" @dragenter="dragleave" @dragover="dragleave">
              <div class="drop_txt" style="margin-top: 70px;"></div>
		      <template>
		        <v-btn dark width="161px" height="40px" rounded
		          >{{ $t("createChooseFile") }}
		        </v-btn>
		        <div class="mt-5">{{ $t("popupNftAppealLimit") }}</div>
		      </template>
		    </div>
		  </FIleInput>
          <Card class="avatar2" owerfrom="create" :value="uploadFileHash" type="sell" />
            <div class="previewtitle mt-3 mb-2 "> {{ $t("preview_market") }}</div>
        
      </div>
    </div>
    <div class="footer box">
      <span class="price"
        >{{ $t("popupAuctionMintFee") }}: {{ value1 }} IRIS</span
      >
<!--      <span class="price"> Gas: {{ value2 }} IRIS</span>-->
      <button class="nosub" disabled @click="subCommit" v-if="subDisabled">
        {{ $t("popupTransferCommit") }}
      </button>
      <button class="sub"  @click="subCommit" v-else>
        {{ $t("popupTransferCommit") }}
      </button>
      <span class="price"
        >{{ $t("popupNewTransferBalance") }}: {{ value3 }} IRIS</span
      >
       <div class="faucet" v-if="value3 == 0" >Insufficient balance. <a href="https://stakely.io/en/faucet/irisnet-iris" target="black">Get Free IRIS Now!</a> </div>
    </div>

    <div class="mask" v-if="showMask">
      <img class="img" src="@/assets/loading.gif" alt="" />
    </div>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <v-snackbar
      v-model="isShowSuccess"
      color="success"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        successTitle
      }}</span></v-snackbar
    >
    <PromptBox ref="promptBox" @commit="promptBoxCommit"></PromptBox>
    <uComponents  ref="ucom"></uComponents>
     <v-dialog class="px-50"  v-model="dialog" v-if="dialog"  width="675">
      <v-card
        id="box"
        class="px-10 overflow-auto"
        height="400"
        style="padding-top: 40px; position: relative;"
      >
        <v-icon
          color="primary"
          style="top: 16px; right: 19px; position: absolute"
          size="30"
          @click="dialog = false"
          >mdi-close-circle</v-icon
        >
        <div v-if="collectionLists && !isMobile" class="collectList d-flex flex-lg-row flex-wrap" v-scroll:#box="onScroll"  >
          <div  class="collect" v-for="(item,index) in collectionLists" :key="index"  >
           <owner-collect-card
          :pageValue="item"
          :hash="item.imgUrl"
          :category='item.category'
          @collectClick = "getImg"      
        >
       
        </owner-collect-card>     
          </div>
        </div>

         <div v-if="collectionLists && isMobile" class="collectListMobile d-flex flex-lg-row flex-wrap" v-scroll:#box="onScroll"  >
          <div  class="collectMobile" v-for="(item,index) in collectionLists" :key="index"  >
           <owner-collect-card
          :pageValue="item"
          :hash="item.imgUrl"
          :category='item.category'
          @collectClick = "getImg"
          
        > 
        </owner-collect-card>
          
          </div>

        </div>
    
      </v-card>
    </v-dialog>
     <v-dialog v-model="openCreateCollection" v-if="openCreateCollection">
         <createCollection  @getOpen="OpenCreateCollection" ></createCollection>
      </v-dialog>
       <v-dialog v-model="openCreateFolder" v-if="openCreateFolder">
         <createFolder  @getOpen="openCreateFolders"  :NFTInfo="FolderNFTInfo"></createFolder>
      </v-dialog>
  </div>
</template>
<script>
import FIleInput from "@/components/FIleInput.vue";
import Card from "@/components/workCard/Card.vue";
import api from "@/api";
import { getFileSrc } from "@/utils/file";
//import { getHash } from "../../utils/getHash";
// import { webSocket } from "@/utils/websocket";
import { issueDenomAndMint,quiryTx,mintNFT } from "../../keplr/iris/wallet";
import { getMyBalance } from "../../keplr/iris/wallet";
import PromptBox from "@/components/PromptBox.vue";
import OwnerCollectCard from '@/components/workCard/OwnerCollectCard.vue';
import CreateCollection from "@/components/popup/createCollection";
import CreateFolder from "@/components/popup/createFolder";

const UPLOAD_KEY = "UPLOAD";
let fileUrl = "";
let imageUrl = "";

export default {
  components: { FIleInput, Card, PromptBox,OwnerCollectCard,CreateCollection,CreateFolder },
  data: () => ({
    refresh_key: 0,
	  fileInputKey:0,
    value1: 0.1,
    value2: "0.075",
    value3: "",
    checkbox: false,
    switch1: false,
    isShowFalse: false,
    isShowSuccess: false,
    falseTitle: "this is a error",
    successTitle: "create success",
    nameValue: "",
    tagValue: "",
    amountValue: 1,
    amountListValue: "",
    categoryValue: "",
    CollectionValue:"",
    FileValue:"",
    descriptionValue: "",
    attribute:3,
    denomType: "",
    isShowLoading: false,
    adult: "",
    issuerName:'',
    isNft:false,
    isCollect:true,
    isFile:true,
    subDisabled: true,
    CategoryList: [
      {
        texts: "Artwork",
        value: 1,
      },
      {
        texts: "Collectibles",
        value: 2,
      },
      {
        texts: "Photography",
        value: 3,
      },
      {
        texts: "Video",
        value: 4,
      },
    ],
    collectionLists:[
   
      
    ],
    FileList:[
    
    ],

    AmountList: [
      {
        text: "Products for sale (available for sale on shelves)",
        value: 1,
      },
      {
        text: "Auctions (Auction sales, only one issue quantity)",
        value: 2,
      },
    ],
    nameRules: [
      (v) => !!v || "Please upload an name for your item",
      (v) => (v && v.length <= 80) || "Name must be less than 80 characters",
    ],
    tagList: [],
    descRules: [(v) => !!v || "Please upload an description for your item"],
    rules: {
      EditionValue: value => {
        const pattern = /^(1|[1-9]\d{0,2}|1000)$/;
        return pattern.test(value) || 'Total release should not exceed 1000.'
      },
    },

    uploadFileHash: {
		imgUrl:"",
      name: "",
      did: "",
      owner: "",
      profilePhoto: "",
    },
    uploadFileUrl: "",
	uploadImageUrl: "",
	showUploadImage: false,
    fileType: "",
    showMask: false,
    commit_msg: "",
	methodType: 11,
    isDrop: false,
    isDropImg: false,
    progressValue: 0,
    flag:true,
    deductionType:'',
    collectionID:'',
    collectionAddress:'',
    symbol:'',
    isshow:false,
     prodList:[{trait_type:'',value:''},
    ],
      isshowLincense:false,
      isVisible: true,
      lincensebox:false,
      ischange: false,
      recentlyCollectList:[],
      collectId:2,
      dialog: false,
      openCreateCollection:false,
       openCreateFolder:false,
       excludeCollectionId:'',
       userdid:'',
       IpfsMetadata:''
  }),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    }
    // showCommitMsg: function() {
    //   return !!this.commit_msg;
    // }
  },
  async mounted() {
    fileUrl = "";
    imageUrl = "";
    window.eventBus.$on('LangChange', this.onLangChange);

    this.CategoryList[0].texts = this.$t("otherpageTabsPageArtwork");
    this.CategoryList[1].texts = this.$t("amCreateCollectibles");
    this.CategoryList[2].texts = this.$t("createCategoriesPhotography");
    this.CategoryList[3].texts = this.$t("amCreateVideo");
    this.AmountList[0].text = this.$t("createProducts");
    this.AmountList[1].text = this.$t("createAuctions");
    //this.AmountList[2].texts = this.$t("amCreateNotSale");
    this.nameRules= [
      (v) => !!v || this.$t("amCreateTipUpload"),
      (v) => (v && v.length <= 80) || this.$t("amCreateTipNameMust"),
    ];
    this.descRules= [(v) => !!v || this.$t("amCreateTipDescription")];
    // this.rules= {
    //   EditionValue: value => {
    //     const pattern = /^([1-9][0-9]{0,2}|1000)$/;
    //     return pattern.test(value) || this.$t("amCreateTipInvalid")
    //   }
    // };
    this.refresh_key++;

    
     await getMyBalance();
	 let mount = localStorage.getItem("key_balance");
   

    this.value3 = mount;
    this.categoryValue = 1
    this.amountListValue =1

    if (this.$store.state.did) {
      let param = { did: this.$store.state.did };

      console.log(this.switch1);
	
      let res = await api.home.getMyInfo(param);
      if(res.data != null){
      this.uploadFileHash.did = res.data.did;
      // this.uploadFileHash.owner = res.data.did;
      this.uploadFileHash.profilePhoto = res.data.profilePhoto;
      this.uploadFileHash.issuerName = res.data.userName;
      this.uploadFileHash.userAddress = res.data.did;
      this.issuerName = this.uploadFileHash.issuerName
      this.userdid = res.data.did
      console.log(this.uploadFileHash.issuerName);
      }else{
        this.$router.push({
        name: "MyInfo"
      });
      }

    }
     if(this.$route.params.imgObj){
       console.log("wxl  --- ",this.$route.params.imgObj)
       debugger
           let nftinfo=this.$route.params.imgObj
       this.excludeCollectionId = nftinfo.contractId ?nftinfo.contractId :nftinfo.contractCollectionId;
   
     
       await this.iconBtn2();
         console.log("wxl --- this.$route.params.imgObj",this.$route.params.imgObj, this.recentlyCollectList)
          // <img class="add" :src="getImageIpfs(item.imgUrl)" />
          //     <div class="name">{{item.name}} </div>
          //     <div class="type">{{item.category}}</div>
         this.recentlyCollectList[0].imgUrl = nftinfo.src;
         this.recentlyCollectList[0].name = nftinfo.name;
          this.collectionAddress = nftinfo.contractAddress; 
          this.collectionID = this.excludeCollectionId
          this.collectId = 0

     }
    
  },
  watch: {
    lincensebox:{
       handler: function (value) {
         if(value){
            this.$gtag.event('创建-CC0 ', { 'event_category': 'Click', 'event_label': 'CC0' })
         }
      },
      immediate: true,
    },
     switch1:{
       handler: function (value) {
         if(value){
            this.$gtag.event('创建-敏感信息', { 'event_category': 'Click', 'event_label': '敏感信息' })
         }
      },
      immediate: true,
    },
    isFile:{
        handler: function() {
        this.submitStatus();
      },
      immediate: true
    },
    
     isCollect:{
      handler: function() {
        this.submitStatus();
      },
      immediate: true

    },
    collectionAddress:{
      handler: function() {
     
        this.submitStatus();
      },
      immediate: true

    },
     nameValue: {
      handler: function(newValue) {
        this.uploadFileHash.name = newValue;
        this.submitStatus();
      },
      immediate: true
    },
     uploadFileUrl: {
      handler: function() {
        this.submitStatus();
      },
      immediate: true
    },
       descriptionValue: {
      handler: function() {
        this.submitStatus();
      },
      immediate: true
    },
     amountValue: {
      handler: function(newValue) {
         this.value1 = (Number( newValue) * 0.1).toFixed(1)
        this.submitStatus();
      },
      immediate: true
    },
      checkbox: {
      handler() {
        this.submitStatus();
      },
      immediate: true
    },
    // nameValue(newValue) {
    //   this.uploadFileHash.name = newValue;
    //   this.submitStatus();
    // },
    amountListValue(newValue) {
        if(newValue==2) {
            this.amountValue = 1;
        }
    },
    // amountValue(newValue){
    //   console.log(newValue)
    //   this.value1 = (Number( newValue) * 0.1).toFixed(1)
    //   console.log(this.value1)
    // }
  },
  methods: {
    itemClicked(item,index){ 
      console.log("wxl --- 3333",item)
      this.collectId = index
     this.ischange = false
      this.collectionID = item.contractId ?item.contractId :item.contractCollectionId;
       this.collectionAddress = item.contractAddress; 
      
    },
    createCollect(){
       this.collectionAddress = ''
       this.collectionID = ''
      if(!this.isCollect){
          this.openCreateCollection = true
      }else{
        this.openCreateFolder =true
      }
    
      this.ischange = true
      this.collectId = 2
    },

      OpenCreateCollection(e){
         this.openCreateCollection = e
    },
      openCreateFolders(e){
      this.openCreateFolder = e
    },
       async cardlist() {     
   
     this.dialog = true;
    //  this.getCollectionList();
    if(!this.isCollect){
      this.iconBtn2();
    }else if(!this.isFile){
this.iconBtn3();
    }
      
    },
     submitStatus() {    
       if(!this.isNft){
           this.subDisabled = !(
        this.nameValue && this.uploadFileUrl && this.descriptionValue  && this.amountValue && this.checkbox && Number(this.value3 )>Number(this.value1)
      ); 
       }else{
           this.subDisabled = !(
        (this.collectionAddress || this.collectionID) && this.nameValue && this.uploadFileUrl && this.descriptionValue  && this.amountValue && this.checkbox && Number(this.value3 )>Number(this.value1)
      ); 
       }
      
    },

    verify() {
      if(!this.isCollect){
        let nameVer = this.$refs.nameValue.validate(true);
        let emailVer = this.$refs.descriptionValue.validate(true);
        !nameVer ? this.$refs.nameValue.focus() : '';
        !emailVer ? this.$refs.descriptionValue.focus() : "";
        return nameVer && emailVer;
      }else{
        let mountVer = this.$refs.amountValue.validate(true);
        let nameVer = this.$refs.nameValue.validate(true);
        let emailVer = this.$refs.descriptionValue.validate(true);
        !mountVer ? this.$refs.amountValue.focus() : '';
        !nameVer ? this.$refs.nameValue.focus() : '';
        !emailVer ? this.$refs.descriptionValue.focus() : "";
        return nameVer && emailVer && mountVer;
      }
     
    },
      sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    iconBtn1(){
      this.isNft = false;
      this.isCollect = true;
      this.isFile = true; 
    },
     async iconBtn2(){
       this.collectionAddress = ''
       this.collectionID = ''
        this.collectId =2
      this.amountValue = 1
       this.isNft = true;
       this.isCollect = false;
       this.isFile = true;

      
       let params = {
         collectionType:1,
          filterType : 13,
         
       }
          if(this.$route.params.imgObj && !this.dialog  ){
    
          params.excludeCollectionId = this.excludeCollectionId


     }
       let res = await api.home.getOwnByCollection(params);
       this.collectionLists=[]
       this.recentlyCollectList=[]
        this.collectionLists = res.data.list
        if(this.collectionLists != null){
          this.collectionLists.forEach(e => { 
            if(this.recentlyCollectList.length < 2)
            this.recentlyCollectList.push(e)
            
          });
     
        }
  
       let num = 0
 
      this.CollectionValue = 1

      
    },
     async iconBtn3(){
        this.collectionAddress = ''
          this.collectionID = ''
        this.collectId =2
             this.amountValue = 1
     this.isNft = true;
       this.isCollect = true;
       this.isFile = false;
       let  params = {
          owner: this.$store.state.did,
         filterType : 12,
          collectionType : 2
        };
      
        
        let res = await api.home.getOwnByCollection(params);
        this.collectionLists=[]
        this.recentlyCollectList=[]
      this.collectionLists = res.data.list
      if(this.collectionLists != null){
          this.collectionLists.forEach(e => { 
            if(this.recentlyCollectList.length < 2)
            this.recentlyCollectList.push(e)
            
          });
     
        }
    
    },
      getImg(imgObj){
          this.collectionID = imgObj.contractId ?imgObj.contractId :imgObj.contractCollectionId;
          this.excludeCollectionId =  this.collectionID
        if(this.recentlyCollectList.length == 0){
          this.recentlyCollectList.push(imgObj)
           this.collectId = 0
        }else if (this.recentlyCollectList.length == 1){


      for (let i = 0; i < this.recentlyCollectList.length; i++) {
       if(this.recentlyCollectList[i].contractId == this.collectionID || this.recentlyCollectList[i].contractCollectionId ==this.collectionID){
          this.collectId = i
            this.dialog = false
            this.collectionAddress = imgObj.contractAddress; 
         return
       }else{
          this.recentlyCollectList.push(imgObj)
            this.collectId = 1
       }
        
      }
          
        }else if(this.recentlyCollectList.length == 2){
  
      for (let i = 0; i < this.recentlyCollectList.length; i++) {
       if(this.recentlyCollectList[i].contractId == this.collectionID || this.recentlyCollectList[i].contractCollectionId ==this.collectionID){
          this.collectId = i
            this.dialog = false
            this.collectionAddress = imgObj.contractAddress; 
         return
       }
        
      }
           this.recentlyCollectList.splice(0,1,imgObj)
           this.collectId = 0
        }
       
       this.collectionAddress = imgObj.contractAddress; 
      this.dialog = false
       this.ischange = false
     

    },

    async subCommit() {

       await getMyBalance();
     
 let mount = localStorage.getItem("key_balance");

      if(mount < parseFloat(this.value1)){
        this.$toast("error", this.$t("NotEnoughFee"));
        return;
      }

      if(!this.verify()) {
            return;
      }
    //判断选择的类型 
    if(!this.isCollect)
    {
        this.methodType = 4
    }
  
      if(this.uploadFileUrl == ""){
        this.$toast("error", "Please upload File")
        return;
      }
      console.log("wxl --- imageUrl",imageUrl)
debugger
	  if(!imageUrl) {
          window.scrollTo(0,0);
          this.$toast("error", this.$t("amCreateSelectCover"));
		  return;
	  }

    if (this.checkbox == false) {
        this.isShowFalse = true;
        this.falseTitle = this.$t("amCreateFalseTitle1");
        return;
     }

      // 获取denomtype   2-卡片  8-拍卖
      if (this.amountListValue === 1) {
        this.denomType = 2;
      }
      // 获取attribute  3-artwork 4-collectibles 5-photography 7-video
      if (this.categoryValue === 1) {
        this.attribute = 3;
      } else if (this.categoryValue === 2) {
        this.attribute = 4;
      } else if (this.categoryValue === 3) {
        this.attribute = 5;
      } else if (this.categoryValue === 4) {
        this.attribute = 7;
		
      }
      try {
        localStorage.setItem("collection_card", 0);
        let name = this.nameValue;
        let sender = this.$store.state.did;
        let denomName = this.nameValue
        let res = await api.home.getFee();
        let fee =0;
        let feeUrl = Number(res.data.MINT_ASSET_FEE);
        let adminAddress = res.data.IrisAddress;
        let data = "";
        let account = Number(this.amountValue);
      
        if(account >1)
        {
           fee = (Number(feeUrl) * Number(account)).toFixed(6);
		 
        }else{
          fee = feeUrl
        }

        this.showMask = true;
         let txHash;
         console.log("wxl ---- mintNFT",sender,this.collectionAddress,denomName,imageUrl, data, account,fee,adminAddress)
        
debugger
        if(!this.isCollect){
        
          txHash = await mintNFT(
            sender,
            this.collectionAddress,
            denomName,
            imageUrl,
            data,
            account,
            fee,
            adminAddress
          )
    this.symbol= this.collectionAddress;
        }else{
           txHash = await issueDenomAndMint(
          name,
          sender,
          sender,
          imageUrl,
          data,
          account,
          fee,
          adminAddress
        );
		this.symbol=txHash.denomInfo[0].value.id;
        }
       
        console.log("txHash", txHash);


        let tokenIds =[];
        for(let i=0;i<txHash.denomInfo.length;i++)
        {
          if(txHash.denomInfo[i].type == "irismod.nft.MsgMintNFT")
          {
            tokenIds.push(txHash.denomInfo[i].value.id)
           
          }  
        }
        //创建参数
      
     let resrece
      let lincense_code = "";
        if(this.lincensebox){
            lincense_code = "CC0";
        }
        if(!this.isCollect){
          let mintParams = {
          contractId:this.collectionID,
          owner: sender,
         tokenIds: tokenIds,
          symbol: this.symbol,
          quantity: Number(this.amountValue),
          description:this.descriptionValue ,
          tags: this.tagList,
		      fileUrl: fileUrl,
          imgUrl: imageUrl,
          buyLimit: "",
          denomType: this.denomType,
          transferLimit: 0,
          minPrice: 0,
          denomName: txHash.denomInfo[0].value.name,
          name: this.nameValue,
          attribute: this.attribute,
          adult: this.switch1,
          properties:JSON.stringify(this.prodList),
          lincenseCode:lincense_code,
          assetPublic:this.isVisible,
        };
        console.log("mintParams", mintParams);
        console.log("xxl string 2 " + JSON.stringify(mintParams));
        let param = {
          lang: "en",
          methodType: this.methodType,
          param: encodeURIComponent(JSON.stringify(mintParams)),
          //hash:receipt.txInfo.hash
          hash: txHash.txInfo.hash,
        };
        console.log("param", param);
         resrece = await api.home.txCache(param);
        console.log("resrece", resrece);
        } else if(!this.isFile)
        {
          
          let mintParams = {
             assetPublic:this.isVisible,
             lincenseCode:lincense_code,
            collection_type:2,
          contractCollectionId:this.collectionID,
          owner: sender,
           tokenIds: tokenIds,
          symbol: this.symbol,
          quantity: Number(this.amountValue),
          description:this.descriptionValue ,
          tags: this.tagList,
		      fileUrl: fileUrl,
          imgUrl: imageUrl,
          buyLimit: "",
          denomType: this.denomType,
          transferLimit: 0,
          minPrice: 0,
          denomName: txHash.denomInfo[0].value.name,
          name: this.nameValue,
          attribute: this.attribute,
          adult: this.switch1,
           properties:JSON.stringify(this.prodList),
        };
        console.log("mintParams", mintParams);
        console.log("xxl string 2 " + JSON.stringify(mintParams));
        let param = {
          lang: "en",
          methodType: this.methodType,
          param: encodeURIComponent(JSON.stringify(mintParams)),
          //hash:receipt.txInfo.hash
          hash: txHash.txInfo.hash,
        };
        console.log("param", param);
         resrece = await api.home.txCache(param);
        console.log("resrece", resrece);
        
        }
        else{
          let mintParams = {
             assetPublic:this.isVisible,
             lincenseCode:lincense_code,
          contractCollectionId:this.collectionID,
          owner: sender,
           tokenIds: tokenIds,
          symbol: this.symbol,
          quantity: Number(this.amountValue),
          description:this.descriptionValue ,
          tags: this.tagList,
		      fileUrl: fileUrl,
          imgUrl: imageUrl,
          buyLimit: "",
          denomType: this.denomType,
          transferLimit: 0,
          minPrice: 0,
          denomName: txHash.denomInfo[0].value.name,
          name: this.nameValue,
          attribute: this.attribute,
          adult: this.switch1,
           properties:JSON.stringify(this.prodList),
        };
        console.log("mintParams", mintParams);
        console.log("xxl string 2 " + JSON.stringify(mintParams));
        let param = {
          lang: "en",
          methodType: this.methodType,
          param: encodeURIComponent(JSON.stringify(mintParams)),
          //hash:receipt.txInfo.hash
          hash: txHash.txInfo.hash,
        };
        console.log("param", param);
         resrece = await api.home.txCache(param);
        console.log("resrece", resrece);
        }
        

        

        
        if(resrece.success == true)
        {
          if (txHash.txInfo.hash) {
                 let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
              this.$mtip({
                 title:this.$t("transactionInprogressSuccess"),
              });
          }
              

                this.showMask = false;
                  if(this.$route.params.imgObj){
                    let collectsource = 'createCollect';
                    let owner = this.$store.state.did
                    let url = this.$route.params.imgObj.linkUrl
                     this.$router.push({
						path: `/collection_create/${url}/${collectsource}/${owner}`,
					});
                  }else{
                       this.$router.push({ name: "PersonalPage" });
            sessionStorage.setItem("PersonalPage_Tabs", 1);
                  }
          

            while (this.flag) {
             
              console.log("wwwwww");
            
              await this.sleep(5000).then(async () => {
                 //console.log(result.hash)
                let res = await quiryTx(txHash.txInfo.hash);
                console.log("wxl -----  quiryTx");
                console.log(res);
                if (res.code == 0) {
              let t = this.$t("notification_create_activity_success")
					let reg=new RegExp('【N】','g')//g代表全部
					let regN=new RegExp('<br/>','g')//g代表全部
					let name = "【" +this.nameValue + "】"
          let title=t.replace(reg,name).replace(regN," ");
                    let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
              this.$mtip({
                title:title,
              });
          }
              
                  this.flag = false;
                } else if (res.code == -1) {
                  console.log("error");
                              let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
                          this.$mtip({
                    title:res.log,
              });
          }
              
              this.flag = false;
                } else {
                  this.flag = true;
                }
              });
            }

        }else{
          this.$toast("error", this.$t(resrece.msg))

        }
        }
     
      } catch (e) {
        //TODO handle the exception
        console.log(e);

        this.showMask = false;
        this.$toast("error", this.$t("amCreateFalseTitle2"));
      }
    },

    //添加标签
    addBtn() {
      let isHas = this.tagList.indexOf(this.tagValue);
      if (this.tagList.length < 5 && this.tagValue && isHas <= -1) {
        this.tagList.push(this.tagValue);
      }
      this.tagValue = "";
    },
    //删除标签
    clearTag(i) {
		console.log(22343,i);
      this.tagList.splice(i, 1);
    },
      addpropertie(){
      if(this.prodList.length < 64)
      this.prodList.push({trait_type:'',value:''})
    
    },
    imgRemove(i){
      console.log("wwwww",i)
      if(this.prodList.length >1)
       this.prodList.splice(i,1)
       console.log("33333",this.prodList)
    },
     moused() {
      console.log("33333333");
      this.isshow = true;
    },
    leave() {
      this.isshow = false;
    },
     mousedlincense(){
      this.isshowLincense = true

    },
    leavelincense(){
      this.isshowLincense = false
    },
    async change(file) {
        // JPG,PNG,GIF,MP4
        let ft = file.name.substr(file.name.lastIndexOf(".")+1);
        if("JPG,PNG,GIF,MP4,JPEG".indexOf(ft.toUpperCase()) == -1) {
            this.$toast("error", this.$t("file_type_not_support"));
            this.fileInputKey++;
            return;
        }

      this.isDrop = true;
      this.fileInputKey++;
      console.log(223,file.name);
      
      console.log("xxl file 1111 :");
      this.fileType = file.type;
      console.log(file.type); //video/mp4
      if (file.type == "video/mp4") {
        if(file.size > 20971520 
		&& this.$store.state.did != "iaa1lv27ac3ztjz6u90y0a8ayr8ql20fzda399cu6j"
		&& this.$store.state.did != "iaa10alustkamdsqknq22pxaqs22ahy8vhht6gte76"
		&& this.$store.state.did != "iaa1dqfet703fj838m9u3yq68h2rczt8fr3e57agal"
		&& this.$store.state.did != "iaa1wzu2k2tsghgj32h5a6allhf69jy2jg2quhlnvd"
		&& this.$store.state.did != "iaa15k328z4v65qd7tp82fcvrymfmfn6lnkq5ep30c"
		&& this.$store.state.did != "iaa1p7vdjq5jspruqn3njywecrdnp87lt9n0fflamh"
		&& this.$store.state.did != "iaa13k9huzzj9uh6z88qdyfpc92n58uxtlaslw00nc") {
            this.isDrop = false;
            this.$toast("error", this.$t("video_not_support"));
            return;
        }
        let res = await api.video.uploadVideo(file, this.onUploadProgress);
        if(res.data.data == null) {
            this.isDrop = false;
            this.$toast("error", res.data.msg);
            return;
        }
        fileUrl = res.data.data;
        console.log(fileUrl);
        this.uploadFileUrl = await getFileSrc(UPLOAD_KEY, res.data.data, true);
		this.showUploadImage = true;
        this.categoryValue = 4;
      } else {
        if(file.size > 10242880) {
            this.isDrop = false;
            this.$toast("error", this.$t("image_not_support"));
            return;
        }
        let res = await api.image.uploadImage(file, this.onUploadProgress);
        if(res.data.data == null) {
            this.isDrop = false;
            this.$toast("error", res.data.msg);
            return;
        }
        imageUrl = res.data.data;
		this.uploadFileHash.imgUrl=imageUrl
        console.log(imageUrl);
        this.uploadFileUrl = await getFileSrc(UPLOAD_KEY, res.data.data);
		this.showUploadImage = false;
		if( this.categoryValue == 4){
			this.categoryValue=1
		}
      }
      this.isDrop = false;
      this.progressValue = 0;
    },
	async imgChange(file) {
        // JPG,PNG,GIF
        let ft = file.name.substr(file.name.lastIndexOf(".")+1);
        if("JPG,PNG,GIF,JPEG".indexOf(ft.toUpperCase()) == -1) {
            this.$toast("error", this.$t("file_type_not_support"));
            this.fileInputKey++;
            return;
        }

        this.isDropImg = true;
		let res = await api.image.uploadImage(file);
        this.fileInputKey++;
        if(!res.data.data) {
            this.isDropImg = false;
            this.isShowFalse = true;
            this.falseTitle=res.data.msg;
            return;
        }
		imageUrl = res.data.data;
		this.uploadFileHash.imgUrl=imageUrl
		this.uploadImageUrl = await getFileSrc(UPLOAD_KEY, res.data.data);
		this.showUploadImage = false;
    this.isDropImg = false;
	},
    closeDialog() {
       
      this.uploadFileUrl = "";
      this.imgUrl = "";
	  this.showUploadImage = false;
	  this.uploadImageUrl = "";
	  this.uploadFileHash.imgUrl="00"
      if(this.categoryValue == 4) this.categoryValue = 1;
    },
    closeImgDialog() {
      imageUrl = ''
      this.uploadImageUrl = "";
      this.imgUrl = "";
	   this.uploadFileHash.imgUrl="00"
	  if(this.fileType=="video/mp4" && this.uploadFileUrl) {
		  this.showUploadImage = true;
		  this.categoryValue=4;
	  } else {
		  this.showUploadImage = false;
	  }
    },
    promptBoxCommit(type) {
        if(type == 1) {
            this.$router.push({ name: "PersonalPage" });
            sessionStorage.setItem("PersonalPage_Tabs", 1);
        }
    },
     clickEyes() {
       this.$gtag.event('创建-是否可见', { 'event_category': 'Click', 'event_label': '是否可见' })
      this.isVisible = !this.isVisible;
    },
      toTerms(){
        if(this.$vuetify.lang.current == 'en'){
          let routeUrl = this.$router.resolve({
            path: "/term",
            query: {id:96}
          });
          window.open(routeUrl.href, '_blank');
        }else{
          let routeUrl = this.$router.resolve({
            path: "/term_zh",
            query: {id:96}
          });
          window.open(routeUrl.href, '_blank');
        }
      },
      
      enentDrop(e) {
          e.stopPropagation();
          e.preventDefault();  //必填字段
          let file = e.dataTransfer.files[0];
          this.change(file);
      },
      enentDropImg(e) {
          e.stopPropagation();
          e.preventDefault();  //必填字段
          let file = e.dataTransfer.files[0];
          this.imgChange(file);
      },
      dragleave(e) {
          e.stopPropagation();
          e.preventDefault();
      },
      onUploadProgress(e) {
          this.progressValue = (e.loaded / e.total) * 100
          console.log(2234,e.loaded, e.total, e.loaded / e.total, this.progressValue);
      },
       async onScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } =
        e.target;
      if (scrollTop + clientHeight === scrollHeight && (this.totalPage > this.pageNumber)) {
        this.pageNumber++;
        this.getCollectionList();
      }

    },
      onLangChange() {
        this.CategoryList[0].texts = this.$t("otherpageTabsPageArtwork");
        this.CategoryList[1].texts = this.$t("amCreateCollectibles");
        this.CategoryList[2].texts = this.$t("createCategoriesPhotography");
        this.CategoryList[3].texts = this.$t("amCreateVideo");
        this.AmountList[0].text = this.$t("createProducts");
        this.AmountList[1].text = this.$t("createAuctions");

        this.nameRules= [
          (v) => !!v || this.$t("amCreateTipUpload"),
          (v) => (v && v.length <= 80) || this.$t("amCreateTipNameMust"),
        ];
        this.descRules= [(v) => !!v || this.$t("amCreateTipDescription")];
        // this.rules= {
        //   EditionValue: value => {
        //     const pattern = /^([1-9][0-9]{0,2}|1000)$/;
        //     return pattern.test(value) || this.$t("amCreateTipInvalid")
        //   }
        // };
        this.refresh_key++;
      }
  },
};
</script>

<style lang="scss" scoped>
.collectListMobile{
   overflow-y: auto;
   text-align: center;
  .collectMobile{
  width: 110px;
	height: 146px;
	background-color: #ffffff;
	border-radius: 5px;
  border: solid 2px #e3e3e3;
  margin-right:10px;
  margin-top: 15px;

      &:nth-child(4n) {
        margin-right: 0;
      }
  }
  .collectImgMobile{
    width: 83px;
  height: 75px;
  object-fit: cover;

  }
  
}
.collectList{
  overflow-y: auto;
  .collect{
  width: 131px;
	height: 146px;
	background-color: #ffffff;
	border-radius: 5px;
  border: solid 2px #e3e3e3;
  margin-right:10px;
  margin-top: 15px;

      &:nth-child(4n) {
        margin-right: 0;
      }
  }
  .collectImg{
    width: 83px;
  height: 75px;
  object-fit: cover;

  }
}
.descb {
  .Viewable {
    height: 12px;
    font-family: Helvetica;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #270645;
  }
  .publicly {
    margin-top: 10px;
    height: 15px;
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #766983;
  }
}
.eye {
  width: 72px;
  height: 72px;
  background-image: linear-gradient(#ffffff, #ffffff),
    linear-gradient(#f8f6fd, #f8f6fd);
  background-blend-mode: normal, normal;
  border-radius: 10px;
  border: solid 2px #e3e3e3;
  .eyeimg {
    width: 70px;
    height: 70px;
  }
}
.title {
 font-family:Helvetica;
  font-size: 15px !important;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #270645;
    display: flex;
  align-items: center;
 
}
.previewtitle{
  font-family:Helvetica;
  font-size: 15px !important;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #270645;
   width: 100%;
   text-align: center;
  
}
.titleInfo{
  font-family:Helvetica;
  font-size: 15px !important;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #270645;
  width: 450px;
}
.explain {
      width: 260px;
      height: 45px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 33px;
      right: 47px;
      span {
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }
    }
    .explainlincense{
      width: 330px;
      height: 170px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 44px;
      right: -65px;
      z-index: 1;
      span {
        margin: auto;
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }

    }
.add_btn{
  	width: 136px;
	height: 40px;
	background-color: #ffffff;
	border-radius: 5px;
  border: solid 1px rgba(158, 158, 158);
}
.list{
  .item{
    .itemkey{
       input {
         border: solid 1px rgba(158, 158, 158);
         border-radius: 5px;
        font-family: Helvetica;
        font-size: 16px;
        height: 60px;
        color: rgba(0, 0, 0, 0.78);
        text-align: right;
        width: 170px;
        margin-left: auto;
        margin-right: 10px;
        padding-left: 10px;

        &:focus {
            outline: none;
        }
    }

    }
    .valuekey{
         input {
         border: solid 1px rgba(158, 158, 158);
         border-radius: 5px;
        font-family: Helvetica;
        font-size: 16px;
         color: rgba(0, 0, 0, 0.78);
        text-align: right;
        width: 220px;
        height: 60px;
        margin-left: auto;
        margin-right: 10px;
        padding-left: 10px;

        &:focus {
            outline: none;
        }
    }


    }
    .img{
      .icon{
        margin-top: 40px;
        margin-left: 10px;
        height: 20px;
        width: 20px;
      }
    }
  }

}
.star {
  color: red;
  margin-left: 10px;
  font-size: 25px;
  margin-top:14px;

}

.box {
  width: 451px;
}
.chooseCollection {
  height: 147px;
  .changerBorde {
    border: solid 2px #7800f4 !important;
  }
  .createCollect {
    text-align: center;
    width: 132px;
    height: 147px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#f8f6fd, #f8f6fd);
    background-blend-mode: normal, normal;
    border-radius: 10px;
    border: solid 2px #e3e3e3;
    .add {
      padding: 20px 24px;
      margin: 15px 23px 10px;
      width: 83px;
      height: 76px;
      background-color: #f2f1f3;
      border-radius: 10px;
      .addCheck {
        color: white;
        font-size: 10px;
        width: 35px;
        height: 35px;
      }
    }
    .name {
   
      height: 10px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    .type {
      margin-top: 10px;
      height: 10px;
      font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
    }
  }
  .uptickCollect {
    text-align: center;
    width: 132px;
    height: 147px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#f8f6fd, #f8f6fd);
    background-blend-mode: normal, normal;
    border-radius: 10px;
    border: solid 2px #e3e3e3;
    .add {
      object-fit: cover;
      margin: 15px 23px 4px;
      width: 83px;
      height: 76px;
      background-image: linear-gradient(
          270deg,
          #f9f7ff 0%,
          #fadcf2 80%,
          #fac0e5 100%
        ),
        linear-gradient(#f8f6fd, #f8f6fd);
      background-blend-mode: normal, normal;
      border-radius: 10px;
    }
    .name {
      font-family: Helvetica;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    }
    .type {
      height: 10px;
      font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
    }
  }
  .ownerCollect {
    width: 132px;
    height: 147px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#f8f6fd, #f8f6fd);
    background-blend-mode: normal, normal;
    border-radius: 10px;
    border: solid 2px #e3e3e3;
  }
  .mycollect {
    width: 28px;
    height: 147px;
    background-image: linear-gradient(#ffffff, #ffffff),
      linear-gradient(#f8f6fd, #f8f6fd);
    background-blend-mode: normal, normal;
    border-radius: 10px;
    border: solid 2px #e3e3e3;
  }
}

.tag_item {
  background-color: #e3e3e3;
  border-radius: 5px;
  border: solid 2px #e3e3e3;
}

.s_witch {
  width: 61px;
  height: 36px;
  border-radius: 18px;

}

.contain {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;

   .main {
    margin-left: 205px;

    .card_img {
      position: relative;

      .creatimg {
        .uploadbox {
          text-align: center;
          margin: 200px auto;
        }
      }

      .clo {
        visibility: hidden;
        position: absolute;
        left: 410px;
        top: 60px;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        z-index: 100;
      }

      &:hover .clo {
        visibility: visible;
      }

      .upload {
        text-align: center;
        vertical-align: middle;
        height: 450px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        border-radius: 5px;
        border: solid 2px #e3e3e3;
      }

      .copyright {
        .content {
          p {
            margin-top: 20px;
            font-family: Helvetica;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;

            span {
              color: #6f58d9;
              text-decoration: none;
            }
          }
        }
      }
    }

    .look {
      margin-top: 16px;
      &::v-deep .work {
        background-color: #e3e3e3;
      }

      &:hover .clo {
        visibility: visible;
      }

      .clo {
        visibility: hidden;
        position: absolute;
        left: 187px;
        top: 5px;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        z-index: 100;
      }

      .card_info {
        width: 280px;
        height: 385px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#6f58d9, #6f58d9);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
      }

      .bom_tit {
        text-align: center;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #766983;
      }

      // .preview {
      //   position: absolute;
      //   top: 320px;
      //   left: 35px;
      // }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;
    margin-left: 205px;
    .faucet{
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #270645;
      a{
        color: #7800f4;
        text-decoration: underline;
      }
    }

    .price {
      display: block;
      font-family:Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      color: #766983;
    }

    .sub {
      margin: 14px auto;
      width: 450px;
      height: 50px;
      background-image: linear-gradient(
          90deg,
          #d300fd 0%,
          #a439fe 26%,
          #7471ff 52%,
          #00fdcf 100%
        ),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
     font-family:Helvetica;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
    }
    .nosub {
      margin: 14px auto;
      width: 450px;
      height: 50px;
      background-image: linear-gradient(#766983, #766983),
      linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
     font-family:Helvetica;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
  
  .drop_area {
    border: 1px dashed #fff;
    height: 400px;
    width: 400px;
    position: relative;

    .drop_txt {
      display: flex;
      flex-direction: column;
      margin-top: 130px;
    }
  }

  .dropwait::after {
    content: "";
    position: absolute;
    // background-color: rgba(0,0,0,0.4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-image: url(../../assets/loading.gif);
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .progress_area {
    width: 400px;
    height: 10px;
    background-color: #bbb;
    border-radius: 30px;
    margin-top: 140px;
    overflow: hidden;

    span {
      display: block;
      background-color: #766983;
      height: 100%;
      width: 0%;
    }
  }
  
  &.mobile {
    padding: 0 !important;

    .main {
      margin-left: 0;

      .card_img {
        width: 100%;

        .creatimg {
        .uploadbox {
          text-align: center;
          margin: 200px auto;
        }
      }

        .clo {
          visibility: hidden;
          position: relative;
          left: 200px;
          top: 40px;
          background-color: #fff;
          border-radius: 50%;
          cursor: pointer;
          z-index: 100;
        }

        &:hover .clo {
          visibility: visible;
        }

        .upload {
          height: 20rem;
        }
      }

      .look {
        margin-bottom: 5rem;

        &:hover .clo {
          visibility: visible;
        }

        .clo {
          visibility: hidden;
          position: relative;
          left: 200px;
          top: 40px;
          background-color: #fff;
          border-radius: 50%;
          cursor: pointer;
          z-index: 100;
        }

        .card_info {
          width: 100%;
          height: 24rem;
        }
      }
    }

    .box {
      width: 100%;
    }

    .footer {
      margin-bottom: 100px;
      margin-left: 0;

      .sub {
        width: 100%;
      }
    }
  }
}

.look {
  &::v-deep .text-number {
    display: none !important;
  }

  &::v-deep .text-avatar {
    padding-bottom: 20px !important;
  }
  
  .avatar2::after {
      content: "";
      width: 100%;
      height: 100px;
      left: 0;
      bottom: 0;
      // background-color: red;
      position: absolute;
  }
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.commit-close {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.commit-logo {
  margin: 20px;
}

.commit-text {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.4rem;
}

.imgfile{
	position: absolute;
	top: 80px;
	left: 55px;
	z-index: 999;
	text-align: center;
}
.contain ::v-deep .v-messages__message {
  color: red;
}
 @media screen and (max-width: 1220px ) {
   .look{
     width: 50% !important;
   }

  }
</style>
